
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Field from "@/components/Field.vue";
import LangSelect from "@/components/LangSelect.vue";
import Logo from "@/components/Logo.vue";
import validator from "validator";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import "../assets/images/logo.svg";
import { RoutesNames } from "../router";

@Component({
  components: {
    Field,
    Card,
    Btn,
    LangSelect,
    Logo
  },
})
export default class SetPasswordView extends Vue {
  @Action setNewPassword;

  email = "";
  currentPassword = "";
  code = "";
  password = "";
  confirmPassword = "";
  loading = false;
  isFormValid = false;

  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];
  codeRules = [(v) => !!v || "Inserire il codice"];
  passwordRules = [
    (v) => !!v || "Inserire la password",
    (v) => v?.length >= 8 || "Minimo 8 caratteri",
  ];
  confirmPasswordRules = [
    (v) => !!v || "Inserire la password",
    (v) => v?.length >= 8 || "Minimo 8 caratteri",
  ];

  @Mutation showError;
  @Mutation showSuccess;
  @Action login;

  mounted() {
    this.$store.commit("setFillHeightOnMainContainer", true);
    this.resetValues();

    if (this.$route.query.email) {
      this.email = this.$route.query.email as string;
    }
  }

  isCodeType(): boolean {
    return this.$route.params.type == "code";
  }

  isCurrentType(): boolean {
    return this.$route.params.type == "current";
  }

  resetValues() {
    (this.$refs.setPasswordForm as Vue & { reset: () => void })?.reset();
  }

  passwordConfirmationRule() {
    return () =>
      this.password === this.confirmPassword || "Le password non coincidono";
  }

  getCopy() {
    return (
      "© " +
      new Date().getFullYear() +
      " CNA™ - " +
      this.$t("allRightsReserved")
    );
  }

  async setPassword() {
    if (
      (this.$refs.setPasswordForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      await this.setNewPassword({
        email: this.email,
        code: this.code,
        password: this.currentPassword,
        newPassword: this.password,
        type: this.$route.params.type,
      });
    }
  }

  goToLogin() {
    this.$router.push({ name: RoutesNames.login });
  }
}
